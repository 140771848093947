import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// General
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import "./deleteIntegrationDialogActions.module.css";
import { Button } from "dms-lib";
const DeleteIntegrationDialogActions = ({ onCancel, onSuccess, }) => {
    const { t } = useI18n();
    return (_jsxs("div", Object.assign({ className: "integration-manager-dialog-actions__wrapper" }, { children: [_jsx(Button, Object.assign({ color: "primary", variant: "contained", onClick: onCancel }, { children: t("cancel", { ns: "Shared" }) })), _jsx(Button, Object.assign({ color: "secondary", variant: "outlined", "data-cr": "confirm-integration-delete", onClick: onSuccess }, { children: t("confirmDelete", { ns: "Shared" }) }))] })));
};
export default DeleteIntegrationDialogActions;
