import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useI18n } from "compass-commons";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import "./deleteIntegrationDialogActions.module.css";
function DeleteIntegrationDialogContent(props) {
    const { t } = useI18n();
    const { subsystems } = props;
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "delete-warning-modal" }, { children: [_jsx(ReportProblemIcon, { sx: { color: "red", fontSize: 44 } }), _jsx("span", { children: t("integrationWithSubsystems") })] })), _jsxs("div", { children: [_jsx("h4", { children: t("subsystemList") }), _jsxs("table", Object.assign({ className: "subsystem-list" }, { children: [_jsxs("thead", { children: [_jsx("th", { children: t("subsystemName") }), _jsx("th", { children: t("siteName") })] }), _jsx("tbody", { children: subsystems.map((subsystem, index) => (_jsxs("tr", { children: [_jsx("td", Object.assign({ className: "break-words" }, { children: subsystem.subsystemName })), _jsx("td", Object.assign({ className: "break-words" }, { children: subsystem.siteName }))] }, `subsystem-${index}`))) })] }))] })] }));
}
export default DeleteIntegrationDialogContent;
