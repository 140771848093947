var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Generaç
import { AllowedModules, CompassAuth } from "compass-shared-services";
const USER_MANAGER_URL = appConfig.USER_MANAGER_SERVICE_PATH;
const URL_PATHS = {
    GET_USER_USERGROUPS: `${USER_MANAGER_URL}/manager/user-groups/logged-user`,
};
const compassAuth = CompassAuth(appConfig.SERVER_URL, appConfig.NAMESPACE);
class UserManagerService {
    static isUserAllowed() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield compassAuth.isUserAllowed(URL_PATHS.GET_USER_USERGROUPS, AllowedModules.INTEGRATIONS);
            }
            catch (error) {
                return false;
            }
        });
    }
}
export default UserManagerService;
