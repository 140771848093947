var IntegrationTemplateType;
(function (IntegrationTemplateType) {
    IntegrationTemplateType["GENERIC"] = "GENERIC";
    IntegrationTemplateType["EMPTY"] = "EMPTY";
    IntegrationTemplateType["XML_PARSER"] = "XML_PARSER";
    IntegrationTemplateType["MQTT"] = "MQTT";
    IntegrationTemplateType["MQTT_SIMPLIFIED"] = "MQTT_SIMPLIFIED";
    IntegrationTemplateType["REST_API"] = "REST_API";
    IntegrationTemplateType["RECEIVER"] = "RECEIVER";
    IntegrationTemplateType["WEATHER"] = "WEATHER";
})(IntegrationTemplateType || (IntegrationTemplateType = {}));
export default IntegrationTemplateType;
