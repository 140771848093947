import NotificationsManagerService from "./NotificationsManagerService";
class ListenerService {
    constructor() {
        this.sockets = new Map();
    }
    /**
     * Method that opens a websocket to a given hubname through the notification manager service.
     * @param hubName - Name of the hub to connect to
     * @param func - Function to be executed when a message is received
     * @param identifier -  Identifier suffix that can be added to allow multiple sockets for the same hub
     */
    listen(hubName, func, identifier = "") {
        const id = hubName + identifier;
        if (!this.sockets.get(id)) {
            NotificationsManagerService.getUserHubToken(hubName)
                .then((res) => {
                if (res) {
                    const socket = new WebSocket(res.Url);
                    socket.onmessage = (messageEvent) => {
                        func(messageEvent.data);
                    };
                    this.sockets.set(id, socket);
                    socket.onopen = () => {
                        NotificationsManagerService.addUserToGroups(hubName).catch((error) => {
                            throw error;
                        });
                    };
                }
            })
                .catch();
        }
    }
    /**
     * Method that closes a given websocket identified by the hubName and an optional identifier suffix
     * @param hubName - Name of the hub that to which the socket is open
     * @param identifier - Identifier suffix that can be added to allow multiple sockets for the same hub
     */
    stopListening(hubName, identifier = "") {
        const id = hubName + identifier;
        const socket = this.sockets.get(id);
        if (socket) {
            this.sockets.delete(id);
            socket.close();
        }
    }
}
export default ListenerService;
