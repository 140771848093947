var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpClient } from "compass-shared-services";
import { getUrl } from "compass-commons";
const SUBSYSTEM_MANAGER_URL = appConfig.SUBSYSTEM_MANAGER_PATH;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
    GET_REGISTRATION_SUBSYSTEMS: `${SUBSYSTEM_MANAGER_URL}/subsystem-configuration/registration/(registrationId)/subsystems`,
};
/**
 * Subsystem manager Service where backend service is called
 */
export default class SubsystemManagerService {
    static getSubsystems(registrationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = {
                registrationId,
            };
            const urlPath = getUrl(URL_PATHS.GET_REGISTRATION_SUBSYSTEMS, uri);
            return httpClient
                .get({ url: urlPath })
                .then((response) => {
                return response;
            })
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                throw Error(JSON.stringify(error));
            }));
        });
    }
}
