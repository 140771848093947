import { jsx as _jsx } from "react/jsx-runtime";
// General
import React from "react";
// Styles
import "./Navbar.module.css";
/**
 * Wrapper for the navbar menu
 * @returns JSX.Element
 */
function Navbar({ children }) {
    return (_jsx("nav", Object.assign({ className: "config-layout__subnav" }, { children: _jsx("ul", Object.assign({ className: "config-subnav__menu" }, { children: children })) })));
}
export default Navbar;
