import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// General
import React from "react";
import { useI18n } from "compass-commons";
// Material UI
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
function TryDeleteIntegrationDialogContent() {
    const { t } = useI18n();
    return (_jsxs("div", Object.assign({ className: "delete-warning-modal" }, { children: [_jsx(ReportProblemIcon, { sx: { color: "red", fontSize: 44 } }), _jsx("span", { children: t("confirmDeleteIntegration") })] })));
}
export default TryDeleteIntegrationDialogContent;
