/**
 * Enum of ContainerInstancesStatusDTO
 */
var ContainerInstancesStatusDTO;
(function (ContainerInstancesStatusDTO) {
    ContainerInstancesStatusDTO["CREATE"] = "CREATE";
    ContainerInstancesStatusDTO["START"] = "START";
    ContainerInstancesStatusDTO["START_STANDBY"] = "START_STANDBY";
    ContainerInstancesStatusDTO["STOP"] = "STOP";
    ContainerInstancesStatusDTO["STOP_STANDBY"] = "STOP_STANDBY";
    ContainerInstancesStatusDTO["DELETE"] = "DELETE";
    ContainerInstancesStatusDTO["DESTROY"] = "DESTROY";
})(ContainerInstancesStatusDTO || (ContainerInstancesStatusDTO = {}));
export default ContainerInstancesStatusDTO;
