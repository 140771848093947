var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpClient } from "compass-shared-services";
import { getUrl } from "compass-commons";
const { NOTIFICATIONS_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
    HUB_TOKEN: `${NOTIFICATIONS_MANAGER_PATH}/azurepubsub/hubs/(hubName)/token`,
    USER_HUB_TOKEN: `${NOTIFICATIONS_MANAGER_PATH}/azurepubsub/hubs/(hubName)/users/token`,
    ADD_USER_TO_GROUPS: `${NOTIFICATIONS_MANAGER_PATH}/azurepubsub/hubs/(hubName)/groups/user`,
};
export const HUBS = {
    COMPASS_INTEGRATION_INFO: "sandbox_integration_manager_info",
};
export default class NotificationsManagerService {
    static getUserHubToken(hubName) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = {
                hubName,
            };
            const urlPath = getUrl(URL_PATHS.USER_HUB_TOKEN, uri);
            return httpClient
                .get({ url: urlPath })
                .then((response) => {
                return response;
            })
                .catch((error) => {
                throw error;
            });
        });
    }
    static addUserToGroups(hubName) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = {
                hubName,
            };
            const urlPath = getUrl(URL_PATHS.ADD_USER_TO_GROUPS, uri);
            return httpClient
                .post({ url: urlPath })
                .then((response) => {
                return response;
            })
                .catch((error) => {
                throw error;
            });
        });
    }
}
