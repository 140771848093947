import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useI18n } from "compass-commons";
import IntegrationManagerService from "../../services/IntegrationManagerService";
import "./IntegrationManagerConfigurator.module.css";
import IntegrationManagerListPanel from "./IntegrationManagerListPanel";
import IntegrationManagerInfo from "./IntegrationManagerInfo";
import { useStateContext } from "../../contexts/StateContext";
import { HUBS } from "../../services/NotificationsManagerService";
const IntegrationManagerConfigurator = (props) => {
    const { t } = useI18n();
    const stateService = useStateContext();
    const { alertSubject } = stateService;
    const [currentIntegrationId, setCurrentIntegrationId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIntegration, setCurrentIntegration] = useState(null);
    const [integrationsMap, setIntegrationsMap] = useState(new Map());
    const [integrationUpdate, setIntegrationUpdate] = useState(null);
    const { listenerService, orgId } = props;
    const errorCallback = useCallback((msg) => {
        if (msg) {
            alertSubject.next({ title: msg });
        }
    }, []);
    const updateIntegrationsList = (list) => {
        let newIntegrationsList = [];
        if (list) {
            newIntegrationsList = list;
            newIntegrationsList.sort((a, b) => a.userContainerInstancesName.localeCompare(b.userContainerInstancesName));
        }
        const newIntegrationsMap = new Map();
        newIntegrationsList.forEach((integration) => {
            newIntegrationsMap.set(integration.containerInstancesId, integration);
        });
        setIntegrationsMap(newIntegrationsMap);
    };
    const loadIntegrations = () => {
        setIsLoading(true);
        IntegrationManagerService.getIntegrations()
            .then((list) => {
            updateIntegrationsList(list);
            setIsLoading(false);
        })
            .catch(() => {
            setIsLoading(false);
            errorCallback(t("failedLoadIntegrations"));
        });
    };
    const handleSelect = (containerInstancesId) => {
        if (containerInstancesId) {
            setCurrentIntegrationId(containerInstancesId);
            setCurrentIntegration(integrationsMap.get(containerInstancesId));
        }
    };
    const handleIntegrationAdd = (integration) => {
        integrationsMap.set(integration.containerInstancesId, integration);
        const list = [];
        list.push(...integrationsMap.values());
        updateIntegrationsList(list);
    };
    const handleIntegrationUpdate = (integration) => {
        const tempIntegrationsMap = new Map(integrationsMap);
        tempIntegrationsMap.set(integration.containerInstancesId, integration);
        setIntegrationsMap(tempIntegrationsMap);
    };
    const handleIntegrationDelete = (integration) => {
        integrationsMap.delete(integration.containerInstancesId);
        const list = [];
        list.push(...integrationsMap.values());
        updateIntegrationsList(list);
        setCurrentIntegration(null);
        setCurrentIntegrationId(null);
    };
    const getIntegrationChangeNotifications = () => {
        listenerService.listen(HUBS.COMPASS_INTEGRATION_INFO, (message) => {
            const receivedMessage = JSON.parse(message);
            setIntegrationUpdate(receivedMessage);
        }, "integration-info");
    };
    useEffect(() => {
        loadIntegrations();
        getIntegrationChangeNotifications();
        return () => listenerService.stopListening(HUBS.COMPASS_INTEGRATION_INFO, "integration-info");
    }, [orgId]);
    useEffect(() => {
        if (integrationUpdate && integrationsMap.get(integrationUpdate.id)) {
            const integration = integrationsMap.get(integrationUpdate.id);
            integration.url = integrationUpdate.url;
            integration.password = integrationUpdate.password;
            integration.username = integrationUpdate.username;
            integration.status = integrationUpdate.status;
            integration.subsystemRegistrationId =
                integrationUpdate.subsystemRegistrationId;
            integration.lastModifiedDate = integrationUpdate.lastModifiedDate;
            integration.lastModifiedBy = integrationUpdate.lastModifiedBy;
            handleIntegrationUpdate(integration);
            if (integrationUpdate.id === currentIntegrationId) {
                setCurrentIntegration(integration);
            }
        }
    }, [integrationUpdate]);
    return (_jsxs("div", Object.assign({ "data-cr": "integration-manager-configurator", className: "config-panel compass-rounded-corner" }, { children: [isLoading && (_jsx("div", Object.assign({ className: "config-main" }, { children: _jsx("div", Object.assign({ "data-cp": "player-spinner", className: "integration-manager-loading" }, { children: _jsx(CircularProgress, { color: "inherit" }) })) }))), !isLoading && (_jsx("div", Object.assign({ className: "config-content" }, { children: _jsxs("div", Object.assign({ className: "config-main" }, { children: [_jsx("div", Object.assign({ className: "integration-manager-list compass-rounded-corner" }, { children: _jsx(IntegrationManagerListPanel, { integrations: integrationsMap, selectedIntegrationId: currentIntegrationId, selectIntegrationCallback: handleSelect, onIntegrationAddCallback: handleIntegrationAdd }) })), _jsx("div", Object.assign({ className: "integration-manager-content compass-rounded-corner" }, { children: _jsx(IntegrationManagerInfo, { selectedIntegration: currentIntegration, onIntegrationUpdateCallback: handleIntegrationUpdate, onIntegrationDeleteCallback: handleIntegrationDelete }) }))] })) })))] })));
};
export default IntegrationManagerConfigurator;
