var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import ReactDOM from "react-dom";
import singleSpaCss from "single-spa-css";
import singleSpaReact from "single-spa-react";
import "@msi/cobalt-react/css/cobalt-light-core.min.css";
import "@msi/cobalt-styles/scss/overlay-prebuilt.scss";
import { LocalizationNS } from "compass-commons";
import { CompassI18n, defaultNS } from "compass-shared-services";
import App from "./App";
import "compass-commons/lib/css/compass-light-core.css";
const cssLifecycles = singleSpaCss({
    cssUrls: [],
    webpackExtractedCss: true,
});
const reactLifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: App,
    errorBoundary(_err, _info, _props) {
        // Customize the root error boundary for your micro frontend here.
        return null;
    },
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const reactLifecyclesMountWithCompass = (props) => __awaiter(void 0, void 0, void 0, function* () {
    const compassI18n = CompassI18n(appConfig.ASSET_STORE);
    yield compassI18n.init({ ns: defaultNS });
    yield compassI18n.loadMoreTranslations(LocalizationNS.INTEGRATIONS);
    // eslint-disable-next-line no-param-reassign
    props.CompassI18n = compassI18n;
    return reactLifecycles.mount(props);
});
export const bootstrap = [cssLifecycles.bootstrap, reactLifecycles.bootstrap];
export const mount = [cssLifecycles.mount, reactLifecyclesMountWithCompass];
export const unmount = [reactLifecycles.unmount, cssLifecycles.unmount];
