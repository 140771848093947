import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// General
import React from "react";
import { useI18n } from "compass-commons";
// Components
import { CircularProgress } from "@mui/material";
import { Button } from "dms-lib";
// Styles
import "./createIntegrationDialog.module.css";
const CreateIntegrationModalActions = (props) => {
    const { t } = useI18n();
    const { onCancel, onSuccess, isSaving } = props;
    return (_jsx("div", Object.assign({ className: "create-integration-dialog-actions__wrapper" }, { children: isSaving ? (_jsx(CircularProgress, { color: "primary" })) : (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ color: "secondary", variant: "outlined", onClick: onCancel }, { children: t("cancel", { ns: "Shared" }) })), _jsx(Button, Object.assign({ "data-cr": "confirm-integration-save", color: "primary", variant: "contained", onClick: onSuccess }, { children: t("save", { ns: "Shared" }) }))] })) })));
};
export default CreateIntegrationModalActions;
