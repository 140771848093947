import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
import { useI18n } from "compass-commons";
import { Info } from "@mui/icons-material";
import CreateIntegrationModalContent from "./CreateIntegrationModalContent";
import CreateIntegrationModalActions from "./CreateIntegrationModalActions";
import IntegrationManagerService from "../../services/IntegrationManagerService";
import { useStateContext } from "../../contexts/StateContext";
import IntegrationTemplateType from "../../models/IntegrationTemplateType";
function CreateIntegrationModal(props) {
    const { t } = useI18n();
    const stateService = useStateContext();
    const { alertSubject } = stateService;
    const { isOpen, handleClose, handleCreateNewIntegrationResponse } = props;
    const [isSaving, setIsSaving] = useState(false);
    const [showSaveIntegrationWarningMessage, setShowSaveIntegrationWarningMessage,] = useState(false);
    const [integrationName, setIntegrationName] = useState("");
    const [brands, setBrands] = useState([
        {
            id: Date.now().toString(),
            name: "",
        },
    ]);
    const [integrationTemplateType, setIntegrationTemplateType] = useState(IntegrationTemplateType.GENERIC);
    const errorCallback = useCallback((msg) => {
        if (msg) {
            alertSubject.next({ title: msg });
        }
    }, []);
    const onChangeSetIntegrationName = (event) => {
        setIntegrationName(event.target.value);
    };
    const onChangeStoreBrands = (brandsList) => {
        const newBrandsList = [];
        brandsList.forEach((brand) => {
            newBrandsList.push(brand);
        });
        setBrands(newBrandsList);
    };
    const onChangeSetIntegrationTemplateType = (templateType) => {
        setIntegrationTemplateType(templateType);
    };
    const handleCancelCreateIntegration = () => {
        if (!showSaveIntegrationWarningMessage) {
            handleClose();
            return;
        }
        setShowSaveIntegrationWarningMessage(false);
    };
    const handleSave = () => {
        if (integrationName === "") {
            errorCallback(t("emptyIntegrationName"));
            return;
        }
        if (brands.length === 0) {
            errorCallback(t("emptyBrandsList"));
            return;
        }
        let invalidBrandFound = false;
        brands.forEach((brand) => {
            if (brand.name === "") {
                invalidBrandFound = true;
            }
        });
        if (invalidBrandFound) {
            errorCallback(t("invalidBrand"));
            return;
        }
        if (!showSaveIntegrationWarningMessage) {
            setShowSaveIntegrationWarningMessage(true);
            return;
        }
        setShowSaveIntegrationWarningMessage(false);
        const newIntegration = {
            name: integrationName,
            brands,
            templateType: integrationTemplateType,
        };
        setIsSaving(true);
        IntegrationManagerService.addIntegration(newIntegration)
            .then((integration) => {
            handleClose();
            const createdIntegration = integration;
            createdIntegration.brands = brands;
            handleCreateNewIntegrationResponse(createdIntegration);
            setIsSaving(false);
            setIntegrationName("");
            setBrands([
                {
                    id: Date.now().toString(),
                    name: "",
                },
            ]);
        })
            .catch((error) => {
            const errorResponse = JSON.parse(error.message);
            errorCallback(`${t("createNok", { ns: "Shared" })} - ${errorResponse.data.errorMessage}`);
            setIsSaving(false);
        });
    };
    return (_jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "sm", open: isOpen, "data-cr": "create-integration-plan-modal", disableEscapeKeyDown: true }, { children: [!showSaveIntegrationWarningMessage && (_jsx(DialogTitle, { children: t("createIntegrationTitle") })), _jsxs(DialogContent, { children: [showSaveIntegrationWarningMessage && (_jsxs("div", Object.assign({ className: "delete-warning-modal" }, { children: [_jsx(Info, { sx: { color: "green", fontSize: 44 } }), _jsx("span", { children: t("createIntegrationInformation") })] }))), !showSaveIntegrationWarningMessage && !isSaving && (_jsx(CreateIntegrationModalContent, { integrationName: integrationName, brands: brands, integrationTemplateType: integrationTemplateType, onChangeSetIntegrationName: onChangeSetIntegrationName, onChangeSetBrandsList: onChangeStoreBrands, onChangeSetIntegrationTemplateType: (templateType) => onChangeSetIntegrationTemplateType(templateType) }))] }), _jsx(DialogActions, { children: _jsx(CreateIntegrationModalActions, { onCancel: handleCancelCreateIntegration, onSuccess: handleSave, isSaving: isSaving }) })] })));
}
export default CreateIntegrationModal;
