import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// General
import React, { useCallback, useEffect, useState } from "react";
import { useI18n } from "compass-commons";
// Components
import { AddRounded } from "@mui/icons-material";
import { Button } from "dms-lib";
import SearchBar from "../commons/searchBar/SearchBar";
import CreateIntegrationModal from "../createIntegrationModal/CreateIntegrationModal";
const IntegrationManagerListPanel = (props) => {
    const { t } = useI18n();
    const { integrations, selectIntegrationCallback, selectedIntegrationId, onIntegrationAddCallback, } = props;
    const [integrationsMap, setIntegrationsMap] = useState(integrations);
    const [createIntegrationModalOpen, setCreateIntegrationModalOpen] = useState(false);
    const searchCallback = useCallback((filter) => {
        if (filter.length > 0) {
            const filteredIntegrations = new Map();
            integrations === null || integrations === void 0 ? void 0 : integrations.forEach((integration, id) => {
                var _a;
                if ((_a = integration.userContainerInstancesName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(filter.toLowerCase())) {
                    filteredIntegrations.set(id, integration);
                }
            });
            setIntegrationsMap(filteredIntegrations);
        }
        else {
            setIntegrationsMap(integrations);
        }
    }, [integrations]);
    useEffect(() => {
        searchCallback("");
    }, [integrations]);
    const handleAdd = () => {
        setCreateIntegrationModalOpen(true);
    };
    const handleCloseCreateIntegrationModal = () => {
        setCreateIntegrationModalOpen(false);
    };
    function getIntegrationsListElements() {
        return (_jsx(_Fragment, { children: (integrationsMap === null || integrationsMap === void 0 ? void 0 : integrationsMap.size) > 0 ? (Array.from(integrationsMap === null || integrationsMap === void 0 ? void 0 : integrationsMap.values()).map((integration) => (_jsx("div", Object.assign({ className: "align-items-start" }, { children: _jsx("button", Object.assign({ "data-cr": "integration-manager-list-item", type: "button", className: integration.containerInstancesId === selectedIntegrationId
                        ? "integration-manager-list-el-selected"
                        : "integration-manager-list-el", onClick: () => selectIntegrationCallback(integration === null || integration === void 0 ? void 0 : integration.containerInstancesId), title: integration.userContainerInstancesName }, { children: _jsx("span", { children: integration.userContainerInstancesName }) }), integration.containerInstancesId) }), integration.containerInstancesId)))) : (_jsx("div", Object.assign({ className: "config-list-empty" }, { children: t("emptyIntegrations") }))) }));
    }
    return (_jsx("div", Object.assign({ "data-cr": "integration-manager-list", className: "config-list-main" }, { children: integrationsMap && (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "config-list-search" }, { children: [_jsx(SearchBar, { searchCallback: searchCallback, dataCr: "integrations-search-bar", placeholder: t("searchAnIntegration") }), _jsx(Button, Object.assign({ color: "primary", variant: "contained", className: "config-list-search-col-2", onClick: handleAdd, icon: true }, { children: _jsx(AddRounded, {}) }))] })), _jsx("div", Object.assign({ className: "config-list-in", "data-cr": "integrations-list-items" }, { children: getIntegrationsListElements() })), _jsx(CreateIntegrationModal, { isOpen: createIntegrationModalOpen, handleClose: handleCloseCreateIntegrationModal, handleCreateNewIntegrationResponse: onIntegrationAddCallback })] })) })));
};
IntegrationManagerListPanel.defaultProps = {
    integrations: null,
    selectedIntegrationId: null,
};
export default IntegrationManagerListPanel;
