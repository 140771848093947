var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpClient } from "compass-shared-services";
import { getUrl } from "compass-commons";
const INTEGRATION_MANAGER_URL = appConfig.SANDBOX_INTEGRATION_MANAGER_PATH;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
    ADD_INTEGRATION: `${INTEGRATION_MANAGER_URL}/containerInstances/`,
    GET_INTEGRATIONS: `${INTEGRATION_MANAGER_URL}/containerInstances/`,
    GET_SPECIFIC_INTEGRATION: `${INTEGRATION_MANAGER_URL}/containerInstances/(containerInstancesId)`,
    GET_SPECIFIC_INTEGRATION_LOGS: `${INTEGRATION_MANAGER_URL}/containerInstances/(containerInstancesId)/logs`,
    UPDATE_INTEGRATION: `${INTEGRATION_MANAGER_URL}/containerInstances/(containerInstancesId)`,
    DELETE_INTEGRATION: `${INTEGRATION_MANAGER_URL}/containerInstances/(containerInstancesId)`,
    FORCE_DELETE_INTEGRATION: `${INTEGRATION_MANAGER_URL}/containerInstances/(containerInstancesId)/force`,
    START_CONTAINER: `${INTEGRATION_MANAGER_URL}/containerInstances/azure/start/(containerInstancesId)`,
    STOP_CONTAINER: `${INTEGRATION_MANAGER_URL}/containerInstances/azure/stop/(containerInstancesId)`,
    GET_CONTAINER_STATUS: `${INTEGRATION_MANAGER_URL}/containerInstances/azure/(containerInstancesId)`,
};
/**
 * Integration manager Service where backend service is called
 */
export default class IntegrationManagerService {
    static getIntegrations() {
        return __awaiter(this, void 0, void 0, function* () {
            const urlPath = `${URL_PATHS.GET_INTEGRATIONS}`;
            return httpClient
                .get({ url: urlPath })
                .then((response) => {
                return response.map((integration) => (Object.assign(Object.assign({}, integration), { id: integration.containerInstanceId })));
            })
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                throw Error(JSON.stringify(error));
            }));
        });
    }
    static addIntegration(containerInstancesRequest) {
        return __awaiter(this, void 0, void 0, function* () {
            const urlPath = `${URL_PATHS.ADD_INTEGRATION}`;
            const body = {
                name: containerInstancesRequest.name,
                brands: containerInstancesRequest.brands,
                templateType: containerInstancesRequest.templateType,
            };
            return httpClient
                .post({ url: urlPath, payload: body })
                .then((response) => {
                return response;
            })
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                throw Error(JSON.stringify(error));
            }));
        });
    }
    static updateIntegration(integration, containerInstancesId) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = {
                containerInstancesId,
            };
            const urlPath = getUrl(URL_PATHS.UPDATE_INTEGRATION, uri);
            return httpClient
                .put({ url: urlPath, payload: integration })
                .then((response) => {
                return response;
            })
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                throw Error(JSON.stringify(error));
            }));
        });
    }
    static deleteIntegration(containerInstancesId) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = {
                containerInstancesId,
            };
            const urlPath = getUrl(URL_PATHS.DELETE_INTEGRATION, uri);
            return httpClient
                .delete({ url: urlPath })
                .then(() => {
                return [];
            })
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                throw Error(JSON.stringify(error));
            }));
        });
    }
    static startContainer(containerInstancesId) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = {
                containerInstancesId,
            };
            const urlPath = getUrl(URL_PATHS.START_CONTAINER, uri);
            return httpClient
                .post({ url: urlPath })
                .then((response) => {
                return response;
            })
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                throw Error(JSON.stringify(error));
            }));
        });
    }
    static stopContainer(containerInstancesId) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = {
                containerInstancesId,
            };
            const urlPath = getUrl(URL_PATHS.STOP_CONTAINER, uri);
            return httpClient
                .post({ url: urlPath })
                .then((response) => {
                return response;
            })
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                throw Error(JSON.stringify(error));
            }));
        });
    }
    static getLogs(containerInstancesId) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = {
                containerInstancesId,
            };
            const urlPath = getUrl(URL_PATHS.GET_SPECIFIC_INTEGRATION_LOGS, uri);
            return httpClient
                .get({ url: urlPath })
                .then((response) => {
                return response;
            })
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                throw Error(JSON.stringify(error));
            }));
        });
    }
}
